import React, { useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import RouteSwitch from "../../components/CustomRouter/RouteSwitch";
import { injectStyleOverride } from "../../components/DiscoverNew/DiscoverNewWrapper";
import ProfileDetails from "./ProfileDetails/ProfileDetails";
import { ProfileStageFlow } from "./ProfileStageFlow/ProfileStageFlow";
import { ProfileStage } from "./ProfileStageFlow/ProfileStage";
import { ProfileIntegrations } from "./ProfileIntegrations/ProfileIntegrations";
import ProfileBilling from "./ProfileBilling/ProfileBilling";
import BillingPayment from "./ProfileBilling/BillingPayment/BillingPayment";
import BillingHistory from "./ProfileBilling/BillingHistory/BillingHistory";
import { useAuthController } from "../../contexts/AuthProvider";
import { PermissionEnum, permissionService } from "../projects/PermissionService";
import BillingMembers from "./ProfileBilling/BillingMembers/BillingMembers";

const getRoutes = (user) => {
  const canEditBilling = permissionService.can(user, PermissionEnum.editOrganizationBilling);
  const canEditMembers = permissionService.can(user, PermissionEnum.editOrganizationMembers);
  const canEditIntegrations = permissionService.can(user, PermissionEnum.editOrganizationIntegrations);
  return [
    { path: "/profile/account-details", Component: ProfileDetails, },
    // ...(canEditIntegrations ? [{
    //   path: "/profile/integrations", Component: ProfileIntegrations, exact: true,
    // }] : []),
    { path: "/profile/interview-process", Component: ProfileStageFlow, exact: true, },
    { path: "/profile/interview-process/:id", Component: ProfileStage },
    ...(canEditMembers || canEditBilling ? [{
      path: "/profile/billing/:tab?",
      Component: ProfileBilling,
      routes: [
        ...(canEditBilling ? [
          {
            path : "/profile/billing/plans",
            Component : BillingPayment,
            exact : true,
          },
          {
            path : "/profile/billing/history",
            Component : BillingHistory,
            exact : true,
          },
        ] : []),
        ...(canEditMembers ? [
          {
            path : "/profile/billing/members",
            Component : BillingMembers,
            exact : true,
          }
        ] : []),
        {
          path: canEditBilling ? "/profile/billing/plans" : "/profile/billing/members",
          type: "redirect",
        },
      ],
    }] : []),
    { path: "/profile/account-details", type: "redirect", },
  ]
};

const getMenuItems = (user) => {
  const canEditBilling = permissionService.can(user, PermissionEnum.editOrganizationBilling);
  const canEditMembers = permissionService.can(user, PermissionEnum.editOrganizationMembers);
  const canEditIntegrations = permissionService.can(user, PermissionEnum.editOrganizationIntegrations);

  return [
    { path: "/profile/account-details", label: "Account Details", },
    { path: "/profile/interview-process", label: "Interview Process", },
    ...(canEditMembers || canEditBilling ? [{ path: "/profile/billing", label: "Billing", }] : []),
    ...(canEditIntegrations ? [{ path: "/profile/integrations", label: "Integrations" }] : [])
  ];
}

const Profile = () => {
  useEffect(() => {
    return injectStyleOverride();
  }, []);
  const authController = useAuthController()
  return (
    <Layout title="Your Account" menuItems={getMenuItems(authController.user)}>
      <RouteSwitch routes={getRoutes(authController.user)} />
    </Layout>
  );
};

export default Profile;
