import * as React from "react";
import { useForm } from "../../../hooks/useForm";
import { companyService } from "../../../screens/companies/CompanyService";
import { FormField } from "../../DiscoverNew/Form/FormField/FormField";
import { Input } from "../../DiscoverNew/Form/Input/Input";
import { Select } from "../../DiscoverNew/Form/Select/Select";
import { Textarea } from "../../DiscoverNew/Form/Textarea/Textarea";
import { Button } from "../../DiscoverNew/UI/Button/Button";

type Props = {
  onSubmit: (values: FormValues) => void
  initialValues: FormValues
  buttonTitle: string
  loading?: boolean
  displayCompany?: boolean
}

type FormValues = {
  name: string
  description: string
  tags: { value: string, label: string }[]
  company?: { value: string, label: string, __isNew__?: boolean }
  companyWebsite?: string
}

export function CreateProjectFormInfo(props: Props) {
  const [companyOptions, setCompanyOptions] = React.useState<{ value: string, label: string }[]>([]);
  const { values, errors, setErrors, onChangeValues } = useForm<FormValues>(props.initialValues);

  const onSubmit = () => {
    const nextErrors: typeof errors = {};

    if (!values.name || !values.name.trim()) {
      nextErrors.name = "This field is required";
    }
    if (!values.company && props.displayCompany !== false) {
      nextErrors.company = "This field is required";
    }

    if (Object.keys(nextErrors).length > 0) {
      setErrors(nextErrors);
      return;
    }

    props.onSubmit(values);
  };

  React.useEffect(() => {
    async function init() {
      const data = await companyService.fetchAll();
      setCompanyOptions(data.map(item => ({
        value : item.id + "",
        label : item.name,
      })));
    }

    init();
  }, []);

  return (
    <form>
      <div style={{ display : "flex", flexDirection : "column", gap : 16, paddingTop : 30 }}>
        <h4 style={{ fontSize : "1.1rem" }}>Project Details</h4>
        <FormField label="Project Name" required error={errors.name}>
          <Input placeholder="e.g. Senior Software Engineer" value={values.name}
                 onChange={e => onChangeValues({ name : e.target.value })} />
        </FormField>
        <FormField label="Description">
          <Textarea value={values.description} minRows={4}
                    placeholder="What is your project about?"
                    onChange={e => onChangeValues({ description : e.target.value })} />
        </FormField>
        <FormField label="Tags">
          <Select placeholder="Start typing to add a new tag" creatable isMulti value={values.tags}
                  formatCreateLabel={v => `Add tag "${v}"`}
                  // @ts-ignore todo
                  onChange={v => onChangeValues({ tags : v })} />
        </FormField>
      </div>
      {props.displayCompany !== false && (
        <div style={{ display : "flex", flexDirection : "column", gap : 16, paddingTop : 30 }}>
          <h4 style={{ fontSize : "1.1rem" }}>Company Details</h4>
          <FormField label="Company" required error={errors.company}
                     supportText="Start typing to find a company or create a new one">
            <Select placeholder="Company Name" creatable value={values.company}
                    options={companyOptions}
                    error={errors.company}
                    formatCreateLabel={v => `Create company "${v}"`}
                    // @ts-ignore todo
                    onChange={v => onChangeValues({ company : v })} />
          </FormField>
          {values.company?.__isNew__ && (
            <FormField label="Website" supportText="Enter the company's website for logo">
              <Input placeholder="www.example.com" value={values.companyWebsite}
                     onChange={e => onChangeValues({ companyWebsite : e.target.value })} />
            </FormField>
          )}
        </div>
      )}
      <footer style={{ paddingTop : 30, display : "flex", gap : 16 }}>
        <Button onClick={onSubmit} disabled={props.loading} style={{ flex : 1 }}>
          {props.buttonTitle}
        </Button>
      </footer>
    </form>
  );
}