import * as api from "../../api/apiService";
import * as globals from "../../constants/constants";
import { COLLABORATOR_ROLE } from "../../constants/constants";
import { collaboratorService } from "./CollaboratorService";
import { candidateService } from "./CandidateService";
import { reduceWorkableJobsToObject } from "utils/projectFeatures";
import { stageFlowService } from "../profile/ProfileStageFlow/StageFlowService";
import { authProviderInstance } from "../../contexts/AuthProvider";
import { companyService } from "../companies/CompanyService";
import { DeleteManuallyCreatedCandidate } from "../../api/apiService";

/**
 * @typedef ProjectInterface
 * @property {number} id
 * @property {number} projectId
 * @property {string} name
 * @property {number} organizationId
 * @property {string} description
 * @property {CompanyInterface} company
 * @property {string} candidateTotal
 * @property {string[]} tags
 * todo
 * @property {string} currentUserRole
 // * todo
 // * @property {any} socialProfiles
 * todo
 * @property {any[]} candidates
 * @property {CollaboratorInterface[]} collaborators
 * @property {CollaboratorInterface | null} ownedBy
 * @property {Date} archivedAt
 */

class ProjectService {
  /**
   * @param {any} obj
   * @returns {ProjectInterface}
   */
  mapToInterface(obj) {
    const collaborators = obj.collaborators || [];
    const ownedBy = collaborators.find((item) => item.role === COLLABORATOR_ROLE.OWNER);
    const currentUserId = authProviderInstance.state.user.id;
    const currentUserRole = collaborators.find((item) => item.id === currentUserId)?.role;

    const data = {
      id: obj.id,
      projectId: obj.id,
      name: obj.name,
      organizationId: obj.organizationId,
      //@todo
      // socialProfiles: obj.socialProfiles || {
      //   facebook: null,
      //   twitter: { url: "https://twitter.com" },
      //   linkedin: null,
      //   github: { url: "https://github.com" },
      //   dribbble: null,
      // },
      description: obj.description,
      company: companyService.mapToInterface(obj.company),
      candidateTotal: obj.candidateTotal,
      tags: obj.tags,
      collaborators: collaborators.filter((item) => item.id !== currentUserId),
      candidates: obj.candidates,
      archivedAt: obj.archivedAt,
      currentUserRole,
      workableJobs: obj.workableJobs?.reduce(reduceWorkableJobsToObject, { closed: [], published: [], archived: [] }),
      ownedBy,
    };
    return data;
  }

  /**
   * @param {Object} args
   * @param {string} args.name
   * @param {string} args.description
   * @param {number} args.companyId
   * @param {number} args.interviewProcessId
   * @param {string[]} args.tags
   * @returns {Promise<ProjectInterface>}
   */
  create = (args) => {
    return api.CreateProject(args).then((r) => (r.data ? this.mapToInterface(r.data) : null));
  };

  /**
   * @param {string} projectId
   * @param {Object} args
   * @param {string} args.name
   * @param {string} args.description
   * @param {number} args.companyId
   * @param {string[]} args.tags
   * @returns {Promise<ProjectInterface>}
   */
  update = (projectId, args) => {
    return api.UpdateProject(projectId, args).then((r) => (r.data ? this.mapToInterface(r.data) : null));
  };

  /**
   * @param {string} id
   * @returns {Promise<any>}
   */
  delete = async (id) => {
    return api.DeleteProject(id);
  };

  /**
   * @param {string} id
   * @returns {Promise<any>}
   */
  archive = async (id) => {
    return api.ArchiveProject(id);
  };

  /**
   * @param {string} id
   * @returns {Promise<any>}
   */
  unarchive = async (id) => {
    return api.UnarchiveProject(id);
  };

  /**

  /**
   * @param {number} id
   * @param {number} candidateId
   * @returns {Promise<any>}
   */
  archiveCandidate = async (id, candidateId) => {
    return api.ArchiveProjectCandidate(id, candidateId);
  };

  /**
   * @param {number} id
   * @param {number} candidateId
   * @returns {Promise<any>}
   */
  unarchiveCandidate = async (id, candidateId) => {
    return api.UnarchiveProjectCandidate(id, candidateId);
  };

  /**
   * @param {string} id
   * @param {Object} args
   * @param {string} args.email
   * @param {('admin'|'editor'|'viewer')} args.role
   * @returns {Promise<null>}
   */
  sendInvite = (id, args) => {
    return api.InviteProjectCollaborator(id, args).then((r) => r.data || null);
  };

  /**
   * @param {number} projectId
   * @param {number} stageId
   * @param {Object} data
   * @returns {Promise<any>}
   */
  updateProjectStageConfig = (projectId, stageId, data) => {
    return api.UpdateProjectStage(projectId, stageId, data).then((r) => r.data || null);
  }

  /**
   * @param {string} id
   * @returns {Promise<any>}
   */
  acceptInvite = (id) => {
    return api.RespondProjectInvite(id, { status: "accepted" }).then((r) => r.data || null);
  };

  /**
   * @param {string} id
   * @returns {Promise<any>}
   */
  declineInvite = (id) => {
    return api.RespondProjectInvite(id, { status: "declined" }).then((r) => r.data || null);
  };

  /**
   * @param {string} projectId
   * @param {string} collaboratorId
   * @returns {Promise<any>}
   */
  revokeCollaboratorAccess = (projectId, collaboratorId) => {
    return api.DeleteProjectCollaborator(projectId, collaboratorId).then((r) => r.data || null);
  };

  /**
   * @param {string} projectId
   * @param {string} collaboratorId
   * @param {Object} args
   * @param {('admin'|'editor'|'viewer')} args.role
   * @returns {Promise<any>}
   */
  updateCollaboratorAccess = (projectId, collaboratorId, args) => {
    return api.UpdateProjectCollaborator(projectId, collaboratorId, args).then((r) => r.data || null);
  };

  /**
   * @param {string} projectId
   * @returns {Promise<any>}
   */
  fetchCollaborators = (projectId) => {
    return api
      .GetProjectCollaborators(projectId)
      .then((r) => (r.data ? r.data.map(collaboratorService.mapToInterface) : []));
  };

  /**
   * @param {number | string} projectId
   * @returns {Promise<any>}
   */
  fetchProject = async (projectId) => {
    const projectResponse = await api.GetProject(projectId);
    if (!projectResponse.data) {
      return null;
    }

    const collaborators = await this.fetchCollaborators(projectId);
    return this.mapToInterface({ ...projectResponse.data, collaborators });
  };

  projectStagesFlowCache = {};
  /**
   * @param {number | string} projectId
   * @param {Record<string, any>} options
   * @returns {Promise<any>}
   */
  fetchProjectStageFlow = async (projectId, options = { force: false }) => {
    if (this.projectStagesFlowCache[projectId] && !options.force) {
      return this.projectStagesFlowCache[projectId];
    }
    const stageFlowResponse = await api.GetStageFlowForProject(projectId);
    if (!stageFlowResponse.data) {
      return null;
    }
    this.projectStagesFlowCache[projectId] = stageFlowService.mapToInterface({
      ...stageFlowResponse.data,
      stages: stageFlowResponse.data.stages.slice().sort((a, b) => a.order - b.order)
    })

    return this.projectStagesFlowCache[projectId];
  };

  /**
   * @param {number | string} projectId
   * @returns {Promise<{value: string, label: string}[]>}
   */
  fetchProjectStageFlowOptions = async (projectId) => {
    const stageFlow = await this.fetchProjectStageFlow(projectId);
    if (!stageFlow) {
      return [];
    }
    return stageFlow.stages.slice().sort((a, b) => a.order - b.order).map((item) => ({
      value: item.stageId,
      label: item.name,
    }));
  };

  /**
   * @param {Object} args
   * @param {number | string} args.projectId
   * @param {number} args.limit
   * @param {number} args.skip
   * @param {string} [args.query]
   * @returns {Promise<{ nodes: ProjectCandidateInterface[], pageInfo: { pageCount: number, total: number } }>}
   */
  fetchProjectCandidates = async (args) => {
    const [candidatesResponse, candidatesCountResponse] = await Promise.all([
      api.GetProjectCandidates(args.projectId, args),
      api.GetProjectCandidatesCount(args.projectId, args),
    ]);

    return {
      nodes: candidatesResponse.data.map((item) => {

        if (item.candidateId === 130338703) {
          item.experiences = item.experiences.map(item => {
            if (item.company?.name === 'chatterworks') {
              item.isPrimary = true;
            }
            return item;
          })
        }

        if (item.candidateId === 104351687) {
          item.experiences = item.experiences.map(item => {
            if (item.company?.name === 'mdc-ventures') {
              item.isPrimary = true;
            }
            return item;
          })
        }

        let latestExperience = item.experiences?.find(item => item.isPrimary);
        if (!latestExperience) {
          latestExperience = item.experiences?.[0];
        }

        return {
          ...item,
          ...candidateService.mapToInterface(item),
          createdAt: item.createdAt,
          stage: item.stage
            ? {
                ...item.stage,
                stageId: item.stage.id,
              }
            : null,
          latestExperience: latestExperience || null,
        };
      }),
      pageInfo: {
        pageCount: Math.max(Math.ceil(candidatesCountResponse.data / args.limit), 1),
        total: candidatesCountResponse.data,
      },
    };
  };

  fetchMyCandidates = async (args) => {
    const [candidatesResponse, candidatesCountResponse] = await Promise.all([
      api.ListMyCandidates('first_name', true, args.limit, args.skip, args.query),
      api.ListMyCandidatesCount('first_name', true, args.limit, args.skip, args.query)
    ]);

    return {
      nodes: candidatesResponse.data.map((item) => {
        return {
          ...item,
          ...candidateService.mapToInterface(item),
          createdAt: item.createdAt,
          stage: item.stage
            ? {
              ...item.stage,
              id: item.stage.stageId,
            }
            : null,
          latestExperience: item.experiences?.length ? item.experiences[0] : null,
        };
      }),
      pageInfo: {
        pageCount: Math.max(Math.ceil(candidatesCountResponse.data / args.limit), 1),
        total: candidatesCountResponse.data,
      },
    };
  };

  fetchManuallyCreatedCandidates = async (args) => {
    const [candidatesResponse, candidatesCountResponse] = await Promise.all([
      api.ListManuallyCreatedCandidates('first_name', true, args.limit, args.skip, args.query),
      api.ListManuallyCreatedCandidatesCount('first_name', true, args.limit, args.skip, args.query)
    ]);

    return {
      nodes: candidatesResponse.data.map((item) => {
        return {
          ...item,
          ...candidateService.mapToInterface(item),
          createdAt: item.createdAt,
          stage: item.stage
            ? {
              ...item.stage,
              id: item.stage.stageId,
            }
            : null,
          latestExperience: item.experiences?.length ? item.experiences[0] : null,
        };
      }),
      pageInfo: {
        pageCount: Math.max(Math.ceil(candidatesCountResponse.data / args.limit), 1),
        total: candidatesCountResponse.data,
      },
    };
  };


  /**
   * @param {Object} args
   * @param {number} [args.candidateId]
   * @param {number} [args.companyId]
   * @param {("mine"|"shared"|"organization")} [args.ownership]
   * @param {boolean} [args.archived]
   * @param {("asc"|"desc")} [args.orderBy]
   * @param {("company_name"|"project_name"|"most_recent")} [args.sortBy]
   * @param {string} [args.query]
   * @param {number} [args.limit]
   * @param {number} [args.skip]
   * @returns {Promise<{ nodes: ProjectInterface[], pageInfo: { pageCount: number, total: number } }>}
   */
  fetchProjects = async (args) => {
    try {
      const [projectsResponse, countResponse] = await Promise.all([api.ListProjects(args), api.ListProjectsCount(args)]);
      for (const project of projectsResponse.data) {
        project.collaborators = project.collaborators?.length
          ? project.collaborators.map(collaboratorService.mapToInterface)
          : [];
      }

      return {
        nodes: projectsResponse.data.map(this.mapToInterface),
        pageInfo: {
          pageCount: Math.max(Math.ceil(countResponse.data / args.limit), 1),
          total: countResponse.data,
        },
      };
    } catch (err) {
      console.error('fetchProjects', err);
      return {
        nodes: [],
        pageInfo: {
          pageCount: 0,
          total: 0,
        },
      };
    }
  };

  /**
   * @param {number} projectId
   * @param {number} candidateId
   * @param {number} stageId
   * @returns {Promise<any>}
   */
  updateCandidateStage = (projectId, candidateId, stageId) => {
    return api.UpdateCandidateStage(projectId, candidateId, stageId);
  };


  /**
   * @param {number} projectId
   * @param {number[]} candidateIDs
   * @returns {Promise<any>}
   */
  removeCandidatesFromProject = (projectId, candidateIDs) => {
    return api.RemoveCandidatesFromProject(projectId, candidateIDs);
  };

  /**
   * @param {number[]} candidateIDs
   * @returns {Promise<any>}
   */
  deleteManuallyCreatedCandidate = (candidateIDs) => {
    const promises = [];
    for (const candidateId of candidateIDs) {
      promises.push(DeleteManuallyCreatedCandidate(candidateId));
    }
    return Promise.all(promises);
  };

  getOrderByOptions = (uiSettings) => {
    const name = uiSettings?.mappings?.project || 'Project';
    return [
      { label: "Company Name (A-Z)", value: globals.COMPANY_NAME_VAL, dir: "ASC" },
      { label: "Company Name (Z-A)", value: globals.COMPANY_NAME_VAL, dir: "DESC" },
      { label: `${name} Name (A-Z)`, value: globals.PROJECT_NAME_VAL, dir: "ASC" },
      { label: `${name} Name (Z-A)`, value: globals.PROJECT_NAME_VAL, dir: "DESC" },
      { label: globals.MOST_RECENT_DISPLAY, value: globals.MOST_RECENT_VAL },
    ];
  };

  fetchWorkableJobs = async (projectId) => {
    const { data } = await api.GetWorkableJobs(projectId);
    return data.reduce(reduceWorkableJobsToObject, { closed: [], published: [], archived: [], draft: [] });
  };

  addCandidatesToWorkable = (candidates, jobId) => {
    return api.AddCandidatesToWorkable({ candidateIDs: candidates, jobId });
  };

  /**
   * @param {ProjectInterface} project
   * @param {object} filters
   * @return {Promise<{ status: 'ready' | 'delayed' | 'error' }>}
   * @type {*}
   */
  downloadPDF = async (project, filters) => {
    const { data: exportData, status } = await api.DownloadProjectCSV(project.projectId, filters?.verifiedOnly, filters.force);
    if (status === 201) {
      return { status : 'delayed' }
    }
    if (status === 208) {
      if (exportData.downloadLink) {
        const fileName = `${project.name}.csv`.replace(/[^a-zA-Z0-9_\-\. ]/g, "")
        const url = exportData.downloadLink;
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        return {
          status: 'ready',
          url,
          fileName
        }
      }
      return {
        status : 'delayed',
        allowRestart: true
      };
    } else if (status === 200) {
      const fileName = `${project.name}.csv`.replace(/[^a-zA-Z0-9_\-\. ]/g, "")
      const url = window.URL.createObjectURL(exportData);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      return {
        status: 'ready',
        url,
        fileName
      }
    }
  }


  /**
   * @param projectId
   * @returns {Promise<any>}
   */
  markExportAsClaimed = async (projectId) => {
    return api.MarkProjectCSVExportAsClaimed(projectId);
  }

  fetchMyProjectsExports = async () => {
    const { data } = await api.GetAllProjectExportsMine();
    return data || [];
  }
}

export const projectService = new ProjectService();
